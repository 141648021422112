import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Luxury Drive Rentals
			</title>
			<meta name={"description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:title"} content={"Luxury Drive Rentals"} />
			<meta property={"og:description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link5" />
			<Override slot="link4" />
			<Override slot="text1" />
			<Override slot="text" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Відчуйте подорож на все життя
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					У Luxury Drive Rentals ми віримо, що кожна подорож має бути незвичайною. Незалежно від того, подорожуєте ви береговою лінією чи подорожуєте міськими вулицями, наша послуга оренди автомобілів преміум-класу створена, щоб надати вам неперевершені враження від водіння. Ми прагнемо пропонувати найвищий рівень обслуговування, якості та розкоші нашим шановним клієнтам.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10:17:01.521Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/2-2.jpg?v=2024-06-19T10%3A17%3A01.521Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10:17:01.510Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="2 / span 1"
						grid-row="2 / span 2"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-2.jpg?v=2024-06-19T10%3A17%3A01.510Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 50px 0" sm-padding="40px 0 30px 0" quarkly-title="Statistics-11">
			<Override
				slot="SectionContent"
				align-items="flex-start"
				flex-direction="row"
				md-flex-wrap="wrap"
				max-width="1440px"
			/>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text letter-spacing="-.06em" color="--dark" margin="0px 0px 0px 0px" font="--headline3">
					Вишуканий автопарк
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text margin="20px 0px 0px 0px" font="--base" color="--dark">
					наша колекція включає широкий вибір розкішних автомобілів, від елегантних спортивних автомобілів до елегантних седанів і потужних позашляховиків. Кожен транспортний засіб вибрано з урахуванням його продуктивності, стилю та витонченості.
				</Text>
			</Box>
			<Box width="49%" padding="20px 20px 20px 20px" md-width="50%" sm-width="100%">
				<Text margin="0px 0px 0px 0px" font="--headline3" letter-spacing="-.06em" color="--dark">
					Неперевершене обслуговування клієнтів
				</Text>
				<Hr margin="40px 0px 40px 0px" border-color="#D6D6D6" />
				<Text color="--dark" margin="20px 0px 0px 0px" font="--base">
					наша команда професіоналів прагне надавати виняткові послуги. Ми доступні цілодобово, щоб допомогти з будь-якими запитами або особливими запитами, які можуть виникнути.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10:17:01.513Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-4.jpg?v=2024-06-19T10%3A17%3A01.513Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Зручне розташування
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							з кількома пунктами посадки та висадки орендувати розкішний автомобіль ніколи не було так просто. Ми пропонуємо гнучкі домовленості відповідно до вашого графіка та уподобань щодо місця розташування.
						</Text>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Конкурентні ціни
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							насолоджуйтесь розкішшю водіння автомобіля високого класу без високої ціни. Ми пропонуємо конкурентні тарифи та прозорі ціни без прихованих комісій.
						</Text>
						<Text margin="0px 0px 15px 0px" font="--headline3" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Комплексне страхування
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							їдьте спокійно, знаючи, що наші пакети оренди включають комплексне страхове покриття, яке гарантує ваш захист під час вашої подорожі.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/services"
							padding="0 0 0 0"
							background="0"
							color="--red"
							type="link"
							text-transform="uppercase"
						>
							Дізнайтеся більше &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Luxury Drive Rentals було засновано з єдиною місією: переосмислити досвід оренди автомобілів
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					{" "}Ми розуміємо, що наші клієнти хочуть більше, ніж просто транспортування, вони хочуть отримати захоплюючий і бездоганний досвід. Наш автопарк складається з найкращих розкішних автомобілів від найпрестижніших світових брендів, які ретельно обслуговуються та оснащені найновішими технологіями для забезпечення вашого комфорту та безпеки.
Наше прагнення до досконалості поширюється не тільки на наші автомобілі. Від моменту, коли ви бронюєте житло, до моменту, коли ви повертаєте ключі, наша команда готова забезпечити бездоганність усіх аспектів оренди. Ми пишаємося своєю увагою до деталей, індивідуальним обслуговуванням і здатністю задовольнити унікальні потреби кожного клієнта.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="block"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="100%"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
					width="100%"
				>
					<Image
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10:17:01.507Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						max-height="700px"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-3.jpg?v=2024-06-19T10%3A17%3A01.507Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-17">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-grid-template-columns="repeat(2, 1fr)"
				grid-auto-flow="row"
				grid-template-columns="repeat(5, 1fr)"
				grid-gap="10px 10px"
				grid-template-rows="auto"
				lg-grid-template-rows="repeat(4, 117px)"
				lg-align-self="center"
				lg-grid-gap="36px"
				width="100%"
				display="grid"
				padding="16px 0px 16px 0px"
				lg-grid-template-columns="repeat(4, 1fr)"
			>
				<Box
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					justify-content="space-between"
					lg-padding="16px 16px 16px 16px"
					display="flex"
					flex-direction="row"
					lg-width="100%"
					padding="16px 0 16px 0"
					lg-order="1"
					width="100%"
					align-items="flex-start"
					sm-margin="0px 0px 0px 0px"
				>
					<Box display="flex" flex-direction="column">
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--lead"
							lg-text-align="center"
							md-text-align="left"
							width="100%"
						>
							Забронюйте автомобіль своєї мрії сьогодні, і нехай пригода почнеться.
						</Text>
					</Box>
				</Box>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					display="flex"
					align-items="center"
					justify-content="center"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 0px 0px"
				>
					<Image
						object-fit="cover"
						position="relative"
						width="100%"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						src="https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10:17:01.502Z"
						display="block"
						top="auto"
						srcSet="https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6662dabafdcae00021e301cc/images/1-1.jpg?v=2024-06-19T10%3A17%3A01.502Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					display="flex"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					lg-justify-content="center"
					hover-transform="translateY(-10px)"
					padding="0px 0px 0px 0px"
					lg-align-items="center"
					lg-display="flex"
					align-items="center"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					justify-content="center"
				>
					<Image
						bottom="0px"
						width="100%"
						left={0}
						position="relative"
						display="block"
						top="auto"
						right={0}
						min-height="100%"
						height="112px"
						src="https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1629896428945-349a9a86e6ba?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="0px 0px 0px 0px"
					justify-content="center"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					display="flex"
					position="relative"
					hover-transform="translateY(-10px)"
					lg-display="flex"
					align-items="center"
				>
					<Image
						width="100%"
						top="auto"
						right={0}
						src="https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						position="relative"
						display="block"
						left={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="0px 0px 0px 0px"
					align-items="center"
					display="flex"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					transition="transform 0.2s ease-in-out 0s"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						height="112px"
						position="relative"
						display="block"
						width="100%"
						right={0}
						srcSet="https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1613035492264-518075adf7e6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});